import { ISvgNavbarProps } from './interface';
import { useDeviceLayout } from '../../../hooks/useDeviceLayout';

export const NavbarBoard = ({ color, onClick }: ISvgNavbarProps) => {
  const { isMobile } = useDeviceLayout();

  return (
    <svg
      width={isMobile ? '60' : '90'}
      height={isMobile ? '52' : '68'}
      viewBox="0 0 53 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="board"
      className="cursor-pointer"
      onClick={onClick}>
      <path
        d="M26.5 3.99967C26.5 2.52691 27.6939 1.33301 29.1667 1.33301H38.5C39.9728 1.33301 41.1667 2.52691 41.1667 3.99967V11.9997C41.1667 13.4725 39.9728 14.6663 38.5 14.6663H29.1667C27.6939 14.6663 26.5 13.4725 26.5 11.9997V3.99967ZM29.8333 5.33301C29.4652 5.33301 29.1667 5.63149 29.1667 5.99967C29.1667 6.36786 29.4652 6.66634 29.8333 6.66634H37.8333C38.2015 6.66634 38.5 6.36786 38.5 5.99967C38.5 5.63149 38.2015 5.33301 37.8333 5.33301H29.8333ZM29.8333 9.33301C29.4652 9.33301 29.1667 9.63149 29.1667 9.99967C29.1667 10.3679 29.4652 10.6663 29.8333 10.6663H37.8333C38.2015 10.6663 38.5 10.3679 38.5 9.99967C38.5 9.63149 38.2015 9.33301 37.8333 9.33301H29.8333Z"
        fill={color}
      />
      <path
        d="M17.4993 4H25.166V6H17.4993C16.2107 6 15.166 7.04467 15.166 8.33333V19.6667C15.166 20.9553 16.2107 22 17.4993 22H20.4977L20.4992 27.0008L27.184 22H35.4993C36.788 22 37.8327 20.9553 37.8327 19.6667V16H38.4993C38.9668 16 39.4156 15.9197 39.8327 15.7724V19.6667C39.8327 22.0599 37.8925 24 35.4993 24H27.8493L21.1643 29.0009C20.4271 29.5523 19.3827 29.4016 18.8314 28.6645C18.6158 28.3763 18.4993 28.0261 18.4993 27.6665L18.4983 24H17.4993C15.1061 24 13.166 22.0599 13.166 19.6667V8.33333C13.166 5.94009 15.1061 4 17.4993 4Z"
        fill={color}
      />
      <path
        d="M17.6152 44.7441V60.4941H16.0332V44.7441H17.6152ZM4.02734 55.9414C7.36719 53.9551 8.9668 51.3096 9.25684 48.0664H4.76562V46.6953H10.918C10.9092 48.2334 10.6982 49.7012 10.2148 51.0723H12.7109V45.1484H14.293V59.7207H12.7109V52.4082H9.63477C8.70312 54.2363 7.2002 55.8359 4.97656 57.1367L4.02734 55.9414ZM25.0599 48.752C25.0423 51.626 26.7826 54.5264 29.2259 55.6074L28.224 56.9258C26.4134 56.0645 24.9808 54.2979 24.2425 52.1445C23.513 54.4561 22.0629 56.3545 20.1732 57.2773L19.1537 55.8711C21.6322 54.7725 23.3724 51.7578 23.3724 48.752V46.0625H25.0599V48.752ZM30.6498 60.5293V44.7441H32.3197V60.5293H30.6498ZM43.4206 46.168V47.5215H41.9265V52.4609C42.6736 52.4082 43.403 52.3379 44.1062 52.25L44.2116 53.498C41.0388 53.999 37.365 54.0693 34.6667 54.0781L34.4734 52.707C35.0798 52.707 35.7302 52.6982 36.407 52.6895V47.5215H34.9128V46.168H43.4206ZM36.864 60.1602V55.2383H38.5339V58.8066H47.5163V60.1602H36.864ZM38.0241 52.6543C38.7712 52.6279 39.5271 52.6016 40.2917 52.5664V47.5215H38.0241V52.6543ZM45.1784 56.3457V44.7617H46.8484V49.4199H49.1335V50.8262H46.8484V56.3457H45.1784Z"
        fill={color}
      />
    </svg>
  );
};
