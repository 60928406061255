import { ISvgNavbarProps } from './interface';
import { useDeviceLayout } from '../../../hooks/useDeviceLayout';

export const NavbarMap = ({ color, onClick }: ISvgNavbarProps) => {
  const { isMobile } = useDeviceLayout();

  return (
    <svg
      width={isMobile ? '60' : '90'}
      height={isMobile ? '52' : '68'}
      viewBox="0 0 36 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="map"
      className="cursor-pointer"
      onClick={onClick}>
      <path
        d="M14.7705 5.33272L14.8332 5.33105L14.8942 5.33267C14.9242 5.33439 14.9541 5.33749 14.9841 5.34203L15.0055 5.34711C15.1194 5.3654 15.2326 5.40608 15.3404 5.46997L15.4096 5.51469L22.17 10.2684L28.926 5.51916C29.5498 5.08077 30.3947 5.4748 30.4919 6.19792L30.5012 6.33724V20.5139C30.5012 20.793 30.3847 21.0571 30.1836 21.2449L30.0763 21.3321L22.743 26.4873C22.3871 26.7374 21.9591 26.7165 21.6392 26.5181L14.8327 21.7297L8.07315 26.4827C7.44953 26.9211 6.60454 26.5271 6.50729 25.8039L6.49805 25.6647V11.4879C6.49805 11.2089 6.61453 10.9447 6.81554 10.7571L6.92295 10.6699L14.2563 5.51469C14.3416 5.45471 14.4311 5.41031 14.5222 5.3802L14.6855 5.34285L14.7705 5.33272ZM28.5012 8.26259L23.1679 12.0118V23.7438L28.5012 19.9946V8.26259ZM13.8314 8.25812L8.49805 12.0073V23.7393L13.8314 19.9901V8.25812ZM15.8346 8.25812V19.9901L21.1679 23.7393V12.0073L15.8346 8.25812Z"
        fill={color}
      />
      <path
        d="M8.88086 49.3848C8.88086 50.2578 9.05664 51.1221 9.4082 51.9775C9.76562 52.833 10.2578 53.5977 10.8848 54.2715C11.5176 54.9395 12.2324 55.4434 13.0293 55.7832L12.1152 57.084C11.1953 56.6797 10.3809 56.0703 9.67188 55.2559C8.96289 54.4355 8.42383 53.4951 8.05469 52.4346C7.68555 53.5654 7.14355 54.5791 6.42871 55.4756C5.71973 56.3721 4.91406 57.043 4.01172 57.4883L3.04492 56.1699C3.81836 55.8066 4.52148 55.2646 5.1543 54.5439C5.78711 53.8174 6.28223 53 6.63965 52.0918C7.00293 51.1777 7.1875 50.2754 7.19336 49.3848V47.7148H3.58984V46.3262H12.4668V47.7148H8.88086V49.3848ZM16.123 60.4941H14.4531V44.7441H16.123V60.4941ZM31.0384 53.3398H26.2748V57.1895H32.6908V58.5605H18.2767V57.1895H24.6224V53.3398H20.0697V45.957H30.8978V47.3105H21.7396V52.0039H31.0384V53.3398Z"
        fill={color}
      />
    </svg>
  );
};
